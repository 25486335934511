import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());
}

var tl = gsap.timeline({});
tl.pause();
tl.set(".loader", {
  display: "block",
})
  .set(".loader__element", {
    transformOrigin: "center right",
  })
  .to(".loader__element", 1, {
    scaleY: 1,
    ease: "expo.inOut",
  })
  .set(".loader__element", {
    transformOrigin: "center bottom",
  })
  .to(".loader__element", 8.5, {
    scaleY: 0,
    ease: "expo.inOut",
    stagger: -0.4,
  })
  .set(".loader", {
    display: "none",
  });

tl.play(0);
